<template class="container">
  <section class="four-step-view">
		<div class="main-title">四步开启你的新加坡留学之路</div>
		<div class="divider"></div>
		<div class="four-steps-container ">
			<div class="step-wrapper"  >
				<img src="assets/img/home/stepImg1.png" alt="">
				<div class="title">了解新加坡留学</div>
				<div class="text">掌握最新留学政策、录取信息直对50+院校保证最佳留学</div>
			</div>
			<div class="step-wrapper">
				<img src="assets/img/home/stepImg2.png" alt="">
				<div class="title">带你挑选专业</div>
				<div class="text">掌握最新留学政策、录取信息直对50+院校保证最佳留学</div>
			</div>
			<div class="step-wrapper">
				<img src="assets/img/home/stepImg3.png" alt="">
				<div class="title">教你挑选学校</div>
				<div class="text">掌握最新留学政策、录取信息直对50+院校保证最佳留学</div>
			</div>
			<div class="step-wrapper">
				<img src="assets/img/home/stepImg4.png" alt="">
				<div class="title">为你定制留学计划</div>
				<div class="text">掌握最新留学政策、录取信息直对50+院校保证最佳留学</div>
			</div>
		</div>
  </section>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}


		.four-step-view {
			width: 80%;
			text-align: center;
			padding-top: 5vw;
      margin: 0 auto;

			.main-title {
        margin: 0 auto;
        width: 70%;
				font-family: 'Inter';
				font-style: normal;
				font-weight: 400;
        font-size: 2vw;
				line-height: 3.5vw;
				color: #000000;
			}
      @media screen and (max-width: 700px) {
        .main-title{
          margin: 0 auto;
          width: 70%;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 7vh;
          color: #000000;
        }
      }

			.divider {
				width: 5%;
				border-bottom: 4px solid #007DD1;
				margin: auto;
				margin-top: 30px;
			}

			.four-steps-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
        //background-image: url("../../assets/myImage/line.png");
        //background-repeat: no-repeat;
        //background-position: center;
        //background-size: contain;
        //margin-top: 5vh;
        position: relative;
        //height: 36vw;


				.step-wrapper {
          margin-top: 1vw;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
					width: 24%;
					padding: 2vw 1vw;
          height: 25vw;
          img{
            width: 11vw;
          }
          img:hover{
            width: 12vw;
          }
					.title {
						margin-top: 30px;
						margin-bottom: 10px;
						font-family: 'Poppins';
						font-weight: 700;
						font-size: 1.3vw;
					}

					.text {
						font-family: 'Poppins';
						font-size: 1vw;
						line-height: 1.5vw;
						color: #838383;
					}
				}
			}
		}

</style>
