<template>
  <!--====================  fun fact area ====================-->
  <div class="funfact-section section-space--inner--100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="fun-fact-wrapper">
            <div class="row">
              <div class="single-fact col-md-3 col-6 section-space--bottom--30">
                <h1 class="counter">
                  <ICountUp :endVal="3000" :options="options1" />
                </h1>
                <h4>成功服务案例</h4>
              </div>
              <div class="single-fact col-md-3 col-6 section-space--bottom--30">
                <h1 class="counter">
                  <ICountUp :endVal="98" :options="options2" />
                </h1>
                <h4>院校申请成功率</h4>
              </div>
              <div class="single-fact col-md-3 col-6 section-space--bottom--30">
                <h1 class="counter">
                  <ICountUp :endVal="95" :options="options2" />
                </h1>
                <h4>新加坡院校覆盖率</h4>
              </div>
              <div class="single-fact col-md-3 col-6 section-space--bottom--30">
                <h1 class="counter">
                  <ICountUp :endVal="99" :options="options2"/>
                </h1>
                <h4>学生满意度</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of fun fact area  ====================-->
</template>

<script>
import data from "../data/funfact.json";

import ICountUp from "vue-countup-v2";
export default {
  components: {
    ICountUp,
  },
  data() {
    return {
      data,
      delay: 300,
      options1: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "+",
      },
      options2: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "%",
      },
    };
  },
};
</script>