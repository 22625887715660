<template class="container">
  <section class="intro-under-carousel row row-cols-1 row-cols-md-2 row-cols-lg-4">
<!--		<div class="intro-wrapper intro-wrapper-first ">-->
<!--			<div class="row-wrapper">-->
<!--				<img src="assets/img/logo.png" alt="logo" />-->
<!--				<div class="text-wrapper">-->
<!--					<div class="title">留学网公开课</div>-->
<!--					<div class="description">教你如何选择适合的院校</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
		<div class="intro-wrapper">
			<div class="row-wrapper">
				<img src="assets/img/home/introIcon1.svg" alt="introIcon1" />
				<div class="text-wrapper">
					<div class="title">新加坡本土留学机构</div>
					<div class="description">掌握最新留学政策、录取信息直对50+院校保证最佳留学方案</div>
				</div>
			</div>
		</div>
		<div class="intro-wrapper">
			<div class="row-wrapper">
				<img src="assets/img/home/introIcon2.svg" alt="introIcon2" />
				<div class="text-wrapper">
					<div class="title">经验丰富顾问团队</div>
					<div class="description">多年海外生活及行业经验为每个家庭定制留学方案</div>
				</div>
			</div>
		</div>
		<div class="intro-wrapper">
			<div class="row-wrapper">
				<img src="assets/img/home/introIcon3.svg" alt="introIcon3" />
				<div class="text-wrapper">
					<div class="title">一站式完整服务体验</div>
					<div class="description">从规划到院校申请，签证办理到落地入学，为您提供一站式服务</div>
				</div>
			</div>
		</div>
  </section>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>

	.intro-under-carousel {
		//width: 100%;
		display: flex;
		justify-content: space-evenly;
		box-shadow: 0px 1px 20px #eee;

		.intro-wrapper {
			width: 25%;
			height: 220px;
			display: flex;
			justify-content: center;
			align-items: center;

			.row-wrapper {
				padding: 0 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;

				.title {
					font-weight: bold;
					padding: 5px 10px;
					font-size: 20px;
				}

				.description {
					color: #838383;
					font-size: 14px;
					padding: 5px 10px;
				}
			}

			img {
				height: 111px;
				width: 111px;
			}
			// border: 1px solid black;
		}

		.intro-wrapper.intro-wrapper-first {
			background: url("../../../public/assets/img/home/introBg.png");
			color: white;

			.row-wrapper {
				background-color: rgba(255, 255, 255, 0.44);
				.description {
					color: #fff;
				}
			}
		}
	}
</style>
