<template>
    <div class="main-container">

        <HeroOne/>
        <IntroUnderCarousel />

        <FourStepView />

        <QnA />

        <SuccessfulCases />

        <ClientFeedback />



    </div>
</template>

<script>
    import Header from '@/components/Header';
    import HeroOne from '../components/sections/HeroOne';
    import ServiceSlider from '../components/sections/ServiceSlider';
    import StudentService from '../components/sections/StudentService';
    import SuccessStory from '../components/sections/SuccessStory';
    import IntroUnderCarousel from '../components/sections/IntroUnderCarousel';
    import FourStepView from '../components/sections/FourStepView';
    import QnA from '../components/sections/QnA';
    import SuccessfulCases from '../components/sections/SuccessfulCases';
    import FormTeacherGroup from '../components/sections/FormTeacherGroup';
    import ClientFeedback from '../components/sections/ClientFeedback';
    import FunFact from '../components/FunFact';
    import AboutSection from '../components/sections/AboutSection';
    import LatestProject from '../components/sections/LatestProject';
    import TeamJob from '../components/sections/TeamJob';
    import Testimonial from '../components/Testimonial';
    import BlogSection from '../components/sections/BlogSection';
    import BrandCarousel from '../components/BrandCarousel';
    import Footer from '../components/Footer';
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'
    import ServiceTabs from '@/components/sections/ServiceTabs';
    import ConsultantQR from '@/components/ConsultantQR';
    import StudentFeedback from '@/components/StudentFeedback';
    import { clientService } from "../services/client";

    import data from '../data/slider.json'
    export default {
        data () {
            return {
                data,
                header:""
            }
        },
        components: {
            Header,
            HeroOne,
            ServiceSlider,
            StudentService,
            SuccessStory,
            ServiceTabs,
            FunFact,
            AboutSection,
            LatestProject,
            TeamJob,
            Testimonial,
            BlogSection,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu,
            ConsultantQR,
            StudentFeedback,
            IntroUnderCarousel,
            FourStepView,
            QnA,
            SuccessfulCases,
            ClientFeedback,
            FormTeacherGroup,
            
        },
    }
</script>
<style scoped>
.main-container{
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
</style>
