<template class="container">
  <section class="client-feedback-view">
		<div class="main-title" style="font-size: 2vw">学生评价</div>
		<div class="divider"></div>
		<div class="client-feedback-wrapper">
			<swiper
				class="swiper"
				v-if="tempData"
				:options="swiperOption"
			>
				<swiper-slide v-for="(item, index) in feedBackData.slice(8,13)" :key="index">
					<div class="feedback-component">
            <div>
              <img style="border-radius: 50%;width: 100px;height: 100px" :src="item.student_image" alt="avatar" class="avatar">
              <h3 style="margin-top: 1vw">{{item.student_name}}</h3>
              <h4>{{item.school_name}},{{item.subject_name}}</h4>
            </div>
<!--						<div class="school">{{item.school}}</div>-->
						<div class="feedback-text">{{item.feedback_content}}</div>
<!--            <div class="major">{{item.create_date}}</div>-->
					</div>
				</swiper-slide>
<!--				<div class="swiper-pagination" slot="pagination"></div>-->
			</swiper>
		</div>
<!--    <div class="btn-row">-->
<!--      <div style="margin: 0 auto" class="myBtn" @click="toMoreQuestions">查看更多</div>-->
<!--    </div>-->
  </section>
</template>

<script>
	import axios from "axios";
  import {httpService} from "@/services/http";
  import {constants} from "@/services/constants";
  export default {
    data() {
			return {
        screenWidth:null,
				swiperOption: {
					slidesPerView: 3,
					spaceBetween: 30,
					height: 900,
					grabCursor: true,
					freeMode: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
				},
        feedBackData:[],
				tempData: [{
					school: "新加坡管理大学",
					feedbackText: "在毕业后经过同学推荐认识了智略国际教育，申请新加坡公立大学对于以前私立大学本科出身的我看来是特别遥不可及。在智略顾问老师的定位分析和文案指导下，我成功拿到了南洋理工大学国际关系专业的录取。 在申请过程中不仅帮助我找到了学业职业新方向，也让我重拾了信心，变得更有勇气，在未来的硕士深造中不断挑战自我！",
					name: "彭心妍",
					major: "本科金融管理专业",
					avatarUrl: "assets/img/home/clientAvatar.png",
				},{
					school: "南洋理工大学",
					feedbackText: "在毕业后经过同学推荐认识了智略国际教育，申请新加坡公立大学对于以前私立大学本科出身的我看来是特别遥不可及。在智略顾问老师的定位分析和文案指导下，我成功拿到了南洋理工大学国际关系专业的录取。 在申请过程中不仅帮助我找到了学业职业新方向，也让我重拾了信心，变得更有勇气，在未来的硕士深造中不断挑战自我！",
					name: "王嘉新",
					major: "本科金融管理专业",
					avatarUrl: "assets/img/home/clientAvatar.png",
				},{
					school: "南洋理工大学",
					feedbackText: "在毕业后经过同学推荐认识了智略国际教育，申请新加坡公立大学对于以前私立大学本科出身的我看来是特别遥不可及。在智略顾问老师的定位分析和文案指导下，我成功拿到了南洋理工大学国际关系专业的录取。 在申请过程中不仅帮助我找到了学业职业新方向，也让我重拾了信心，变得更有勇气，在未来的硕士深造中不断挑战自我！",
					name: "彭心妍",
					major: "本科金融管理专业",
					avatarUrl: "assets/img/home/clientAvatar.png",
				},{
					school: "南洋理工大学",
					feedbackText: "在毕业后经过同学推荐认识了智略国际教育，申请新加坡公立大学对于以前私立大学本科出身的我看来是特别遥不可及。在智略顾问老师的定位分析和文案指导下，我成功拿到了南洋理工大学国际关系专业的录取。 在申请过程中不仅帮助我找到了学业职业新方向，也让我重拾了信心，变得更有勇气，在未来的硕士深造中不断挑战自我！",
					name: "王嘉新",
					major: "本科金融管理专业",
					avatarUrl: "assets/img/home/clientAvatar.png",
				},{
					school: "南洋理工大学",
					feedbackText: "在毕业后经过同学推荐认识了智略国际教育，申请新加坡公立大学对于以前私立大学本科出身的我看来是特别遥不可及。在智略顾问老师的定位分析和文案指导下，我成功拿到了南洋理工大学国际关系专业的录取。 在申请过程中不仅帮助我找到了学业职业新方向，也让我重拾了信心，变得更有勇气，在未来的硕士深造中不断挑战自我！",
					name: "彭心妍",
					major: "本科金融管理专业",
					avatarUrl: "assets/img/home/clientAvatar.png",
				},]
			}
		},
    created() {
      if (window.innerWidth < 600){
        this.swiperOption.slidesPerView = 1
      }
      this.getFeedbacks()
    },
    methods:{
      getFeedbacks(){
        httpService.get(constants.STUDENT_FEEDBACK).then((res) => {
          this.feedBackData = res.body
          console.log(res.body)
        })
      }
    }
	}
</script>

<style lang="scss" scoped>
.client-feedback-view .client-feedback-wrapper .swiper-slide .feedback-component[data-v-298d2031]::after{
  top:-28px
}
		.client-feedback-view {
			width: 80%;
      margin: 0 auto;
			.client-feedback-wrapper {
				display: flex;
				justify-content: center;
        //flex-wrap: wrap;

				.swiper-slide {
					.feedback-component {
            max-height: 500px;
            min-height: 500px;
            overflow: auto;
            text-align: center;
						border: 1px solid #EEEDEE;
						box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
						border-radius: 20px;
						margin-top: 20px;
						position: relative;
						padding: 30px 40px;

						.school {
							color: #170F49;
							font-family: 'DM Sans';
							font-weight: 700;
							font-size: 22px;
						}

						.feedback-text {
							color: black;

						}

						.student-info {
							display: flex;
							align-items: center;
							padding-top: 30px;
							font-size: 18px;

							img.avatar {
								margin-right: 30px;
								width: 80px;
								height: 80px;
							}

							.name {
								color: #170F49;
								margin-top: 3vw;
							}

							.major {
								color: #838383;
							}
						}
					}

					.feedback-component::after {
						content: "“";
						position: absolute;
						top: -35px;
						font-family: 'Noto Sans SC', sans-serif;
						right: 50px;
						font-size: 60px;
						font-weight: bold;
						color: #007DD1;
					}
				}
			}

			.main-title {
				text-align: center;
				font-family: 'Inter';
				font-style: normal;
				font-weight: 400;
				font-size: 50px;
				line-height: 61px;
				color: #000000;
			}

			.divider {
				width: 5%;
				border-bottom: 4px solid #007DD1;
				margin: auto;
				margin-top: 30px;
				margin-bottom: 30px;
			}

		}
</style>
