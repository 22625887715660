<template>
  <section style="width: 80%;margin: 0 auto">
		<div class="main-title" style="font-size: 2vw">近期录取案例</div>
		<div class="divider"></div>
    <div>
      <el-carousel :interval="3000" indicator-position="outside" height="32vw">
        <el-carousel-item indicator-position="none" v-for="item in hotSubjects" :key="item.id">
          <div style="display: flex;justify-content: space-between;padding: 4px .5vw">
            <successCard
                v-for="itemSubject of item.subjects"
                :img="itemSubject.admission_notice"
                :name="itemSubject.name"
                :school="itemSubject.school_apply_chs"
                :time="itemSubject.subject_start_date"
                :zhuanye="itemSubject.subject_apply_chs"
                :tag="itemSubject.subject_level"
            ></successCard>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </section>
</template>

<script>
  import 'swiper/css/swiper.css'
	import { schoolService } from "../../services/school";
  import successCard from "@/components/SuccessCard/successCard";

	export default {
		name: "SuccessCases",
    components:{
      successCard
    },
		data() {
			return {
				successStory: "",
				swiperOption: {
					slidesPerView: 4,
					slidesPerColumn: 2,
					width: '1900',
					// height: 1500,
					spaceBetween: 30,
					grabCursor: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
				},
        hotSubjects:[
          {id:1,subjects:[]},
          {id:2,subjects:[]},
          // {id:3,subjects:[]},
        ]
			}
		},
    created() {
      schoolService.getSuccessCase().then((data) => {
        console.log(data)
        this.hotSubjects[0].subjects = data.results.slice(0,4)
        this.hotSubjects[1].subjects = data.results.slice(4,8)
        // this.hotSubjects[2].subjects = data.results.slice(8,9)
      })
    }
	}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 20vw;
  margin: 0;

}

.el-carousel__item:nth-child(2n) {


}

.el-carousel__item:nth-child(2n+1) {


}
.divider {
  width: 5%;
  border-bottom: 4px solid #007DD1;
  margin: 30px auto;
}
		.successful-cases-view {
			width: 90%;
			padding-top: 5vw;
      margin: 0 auto;
			.successful-cases-wrapper {
				display: flex;
				justify-content: center;
			}

			.main-title {
				text-align: center;
				font-family: 'Inter';
				font-style: normal;
				font-weight: 400;
				font-size: 7vw;
				//line-height: 61px;
				color: #000000;
			}



			.swiper {
				margin: 30px;
				height: 80vw;


				.swiper-slide {
					height: 37vw;
					box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
					border-radius: 10px;

					.img-wrapper {
						position: relative;
            height: 28vw;
						img.offerImg {
							width: 100%;
              height: 100%;
						}

						.tag {
							position: absolute;
							background-color: #FBB040;
							color: #FFF;
							right: 20px;
							top: 20px;
							width: 115px;
							text-align: center;
							font-family: 'DM Sans';
							font-size: 1vw;
							font-weight: 700;
							border-radius: 13px;
							padding: 6px 0;
						}

					}


					.desciption {
						display: flex;
						justify-content: space-between;
            margin-top: 1.5vw;
            width: 25vw;
            padding-left: 1vw;
						img.avatar {
							width: 4vw;
							height: 4vw;
						}

						.text-container {
							font-family: 'DM Sans';
              padding-left: -2vw;
							.school {
								font-size: 1.2vw;
								font-weight: 500;
								width: 15vw;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
							}

							.major {
								color: #9D9D9D;
								font-size: .8vw;
								width: 18vw;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;

							}
						}
					}
				}
			}

		}
</style>
