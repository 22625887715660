import Vue from 'vue'
import { httpService } from './http'
import { constants } from './constants'

var clientService = {
    getHeaders: async function () {
        let returnData = await httpService.get(constants.HEADER_CAROUSELS)
        return returnData.data
    },
    getStudentFeedbacks: async function () {
        let returnData = await httpService.get(constants.STUDENT_FEEDBACK)
        return returnData.data
    },
}

export {
    clientService
};