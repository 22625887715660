<template>
  <div>
    <div class="container">
      <div class="row">
        <div
          class="col-lg-4 col-12 order-1 order-lg-1"
          style="background-color: #2B63AE"
        >
          <h3 style="text-align: center; padding-top: 30px; color: white">
            咨询留学顾问老师
          </h3>
          <br />
          <b-avatar
            style="display: block; margin-left: auto; margin-right: auto"
            src="https://placekitten.com/300/300"
            size="14rem"
          ></b-avatar>
          <br />
          <img
            src="../../public/assets/img/qr.jpg"
            style="
              width: 150px;
              display: block;
              margin-left: auto;
              margin-right: auto;
              padding-bottom: 30px;
            "
          />
        </div>

        <div
          class="col-lg-8 col-12 order-1 order-lg-2"
          v-if="submited == 'false'"
        >
          <div class="contact-form" style="padding-top: 30px">
            <h3 style=" text-align: center">在线申请</h3>

            <div id="contact-form">
              <div class="row row-10">
                <div class="col-md-6 col-12 section-space--bottom--20">
                  <input
                    name="con_name"
                    type="text"
                    placeholder="中文姓名"
                    v-model="form.name_chs"
                  />
                </div>
                <div class="col-md-6 col-12 section-space--bottom--20">
                  <input
                    name="con_name"
                    type="text"
                    placeholder="微信号"
                    v-model="form.wechat"
                  />
                </div>
                <div class="col-md-6 col-12 section-space--bottom--20">
                  <input
                    name="con_name"
                    type="number"
                    placeholder="手机号码"
                    v-model="form.handphone"
                  />
                </div>
                <div class="col-md-6 col12 section-space--bottom--20"></div>
                <div class="col-md-6 col12 section-space--bottom--20">
                  <b-form-select
                    v-model="form.stage"
                    :options="stages"
                  ></b-form-select>
                </div>
                <div class="col-md-6 col12 section-space--bottom--20">
                  <b-form-select
                    v-model="form.budget"
                    :options="budget"
                  ></b-form-select>
                </div>
                <div class="col-md-6 col12 section-space--bottom--20">
                  <b-form-select
                    v-model="form.contact_time"
                    :options="timeSlot"
                  ></b-form-select>
                </div>
                <div class="col-md-6 col12 section-space--bottom--20">
                  <b-form-select
                    v-model="form.estimate_entry"
                    :options="estimateTime"
                  ></b-form-select>
                </div>

                <div class="col-12">
                  <textarea
                    name="con_message"
                    placeholder="留学要求"
                    v-model="form.additional"
                  ></textarea>
                </div>
                <div class="col-12" style="padding-top: 10px">
                  <b-alert v-model="showAlert" variant="danger">
                    请完整填写表单
                  </b-alert>
                </div>
                <div class="col-12">
                  <button
                    v-on:click="onSubmit"
                    style="
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                    "
                  >
                    提交
                  </button>
                </div>
              </div>
            </div>
            <p class="form-message"></p>
          </div>
        </div>

        <div
          class="col-lg-8 col-12 order-1 order-lg-2"
          v-if="submited == 'submitting'"
        >
          <h3 style="color: white; text-align: center; padding-top: 30px">
            正在提交
          </h3>
          <div style="padding-top: 100px">
            <b-icon
              style="
                color: white;
                display: block;
                margin-left: auto;
                margin-right: auto;
              "
              icon="truck"
              animation="cylon"
              font-scale="4"
              shift-v="0"
            ></b-icon>
            <b-icon
              style="
                color: white;
                display: block;
                margin-left: auto;
                margin-right: auto;
              "
              icon="three-dots"
              animation="cylon"
              font-scale="4"
            ></b-icon>
          </div>
        </div>

        <div
          class="col-lg-8 col-12 order-1 order-lg-2"
          v-if="submited == 'success'"
        >
          <div style="padding-top: 100px">
            <b-iconstack
              font-scale="10"
              style="
                color: white;
                display: block;
                margin-left: auto;
                margin-right: auto;
              "
            >
              <b-icon stacked icon="circle"></b-icon>
              <b-icon stacked icon="check"></b-icon>
            </b-iconstack>
            <h3 style="color: white; text-align: center; padding-top: 30px">
              提交成功
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mondayService } from "../services/monday";
export default {
  props: ["items"],
  data() {
    return {
      form: {
        name_chs: "",
        wechat: "",
        handphone: null,
        stage: null,
        budget: null,
        contact_time: null,
        estimate_entry: null,
        additional: "",
      },
      stages: [
        { value: null, text: "请选择你的留学阶段", disabled: true },
        { value: "0", text: "幼儿园" },
        { value: "101", text: "小学" },
        { value: "14", text: "中学" },
        { value: "6", text: "高中" },
        { value: "108", text: "大学" },
        { value: "7", text: "研究生" },
        { value: "5", text: "其他学历" },
      ],
      budget: [
        { value: null, text: "请选择你的预算", disabled: true },
        { value: "16", text: "10万人民币以下" },
        { value: "106", text: "10万-20万人民币/年" },
        { value: "0", text: "20-30万人民币/年" },
        { value: "1", text: "无指定要求" },
      ],
      timeSlot: [
        { value: null, text: "请选择你的方便的联系时间", disabled: true },
        { value: "0", text: "上午9-12点" },
        { value: "15", text: "下午12-3点" },
        { value: "106", text: "下午3-6点" },
        { value: "12", text: "下午6点以后" },
        { value: "1", text: "任意时间均可" },
      ],
      estimateTime: [
        { value: null, text: "请选择预计留学时间", disabled: true },
        { value: "15", text: "三个月内" },
        { value: "4", text: "六个月内" },
        { value: "9", text: "一年内" },
        { value: "3", text: "明年、之后" },
      ],
      submited: "false",
      showAlert: false,
      formValid: true,
    };
  },
  methods: {
    async onSubmit() {
      for (const property in this.form) {
        if (this.form[property] == null || this.form[property] == "") {
          this.formValid = false;
          this.showAlert = true;
          break;
        } else {
          this.formValid = true;
        }
        //console.log(`${property}: ${this.form[property]}`);
      }
      if (this.formValid == true) {
        this.submited = "submitting";
        var body = {
          query: `mutation ($boardId: Int!, $groupId: String, $itemName: String, $columnValues: JSON!) {create_item (board_id: $boardId,group_id: $groupId,item_name: $itemName,column_values: $columnValues) {id}}`,
          variables: {
            boardId: 762166387,
            groupId: "new_group50794",
            itemName: this.form.name_chs,
            columnValues: JSON.stringify({
              text: this.form.wechat,
              text0: this.form.handphone,
              status2: this.form.budget,
              status_1: this.form.contact_time,
              status_2: this.form.estimated_entry,
              status3: this.form.highest_qualification,
            }),
          },
        };
        let resForItem = await mondayService.post(body);
        if (resForItem != null) {
            //console.log(resForItem.data);
          let bodyForUpdate = {
            query: `mutation ($itemId: Int, $body: String!) {create_update (item_id: $itemId,body: $body) {id}}`,
            variables: {
              itemId: parseInt(resForItem.data.data.create_item.id),
              body: this.form.additional,
            },
          };
          let resForUpdate = await mondayService.createUpdate(bodyForUpdate);
          if (resForUpdate != null) {
              //console.log(resForUpdate.data);
            this.submited = "success";
          } else {
            this.submited = "failed";
          }
        } else {
          this.submited = "failed";
        }
        //console.log(resForItem.data);
        //console.log(resForUpdate.data);
      }
      //console.log(this.form);
    },
  },
};
</script>