<template class="container">
  <section class="q-and-a-view">
		<div class="main-title">留学问答</div>
		<div class="divider"></div>
		<div class="q-and-a-wrapper">

			<div class="row-container row">
				<div class="item-container big-item col-lg-7">
<!--					<img :src='localData1.answerer.agent.avatar' alt="" class="avatar">-->
          <img src='https://randomavatar.study65.com/' alt="" class="avatar">
					<div class="username">{{localData1.questioner}}</div>
					<div class="title-group">
						<span class="topic">#{{localData1.classification}}:</span>
						<span class="title">{{localData1.question_content}}</span>
					</div>
					<div class="text">{{localData1.answer_content}}</div>
<!--					<div class="teacher">顾问老师:{{localData1.answerer.username}}</div>-->
				</div>
				<div class="item-container col-lg-4" v-for="item of localData.slice(1,4)" :key="item.id">
					<img :src='item.wechat_user.avatar_url' alt="" class="avatar">
<!--          <img src='https://randomavatar.study65.com/' alt="" class="avatar">-->
					<div class="username">{{item.questioner}}</div>
					<div class="title-group">
						<span class="topic">#{{item.classification}}:</span>
						<span class="title">{{item.question_content}}</span>
					</div>
					<div class="text">{{item.answer_content}}</div>
<!--					<div class="teacher">顾问老师:{{item.answerer.username}}</div>-->
				</div>
				<div class="qrcode-question col-lg-3">
					<div class="text-row">
						<div class="title">我要提问</div>
						<img src="assets/img/home/questionIcon.svg" alt="question-mark" class="question-icon" />
					</div>
					<div class="img-wrapper">
						<img src="../../assets/myImage/QnA.png" alt="" class="qrcode">
					</div>
				</div>
			</div>
      <div class="btn-row">
        <div style="margin: 0 auto" class="myBtn" @click="toMoreQuestions">查看更多</div>
      </div>
		</div>
  </section>
</template>

<script>
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";

export default {
			data() {
				return {
          localData1:'',
					localData: '',
          questionData:[]
				}
			},
      created() {
        httpService.get(constants.encyclopedia).then(((data)=>{
          this.localData = data.body.results.slice(1,5)
          this.localData1 = data.body.results[1]

          console.log(this.localData)
        }))
      },
  methods:{
    toMoreQuestions(){
      this.$router.push({path:'/moreQuestions'})
    }
  }
    }
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}

		.q-and-a-view {
      background-color: #F7F7F7;
			width: 100%;
      padding: 1vw 0;
      margin-top: 1vw;
			.main-title {
				text-align: center;
				font-family: 'Inter';
				font-style: normal;
				font-weight: 400;
				font-size: 2vw;
				line-height: 2vw;
				color: #000000;
			}

			.divider {
				width: 5%;
				border-bottom: 4px solid #007DD1;
        margin: 30px auto;
      }

			.q-and-a-wrapper {
				background-color: #F7F7F7;
				display: flex;
				align-items: center;
				flex-direction: column;
        margin: 0 auto;
        width: 80%;
				//min-width: 1200px;
				.btn-row {
					//text-align: center;
          margin:0 0 auto;
					.more-qna-btn {
						color: #FFFFFF;
						background-color: #007DD1;
						font-family: 'DM Sans';
						font-weight: 700;
						font-size: 1.1vw;
						padding: 10px 20px;
						display: inline-block;
						border-radius: 30px;
						width: auto;
						margin: 0 auto;
						cursor: pointer;
            text-align: center;
					}
				}

				.row-container {
					//width: 80%;
					display: flex;
					//max-width: 1960px;
					//min-width: 1200px;
					flex-wrap: wrap;
					justify-content: space-between;

					.item-container.big-item {
						// background-color: ;
						//width: 60%;
					}

					.item-container {
						//width: 30%;
						background: #FFFFFF;
						border: 1px solid #E7E7EA;
						box-sizing: border-box;
						box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
						border-radius: 20px;
						font-family: 'DM Sans';
						padding: 4vw 3vw 1.7vw;
						position: relative;
						margin: 3vw 15px;
            //height: 440px;

						img.avatar {
							position: absolute;
							width: 120px;
							height: 120px;
							left: 40px;
							top: -60px;
              border-radius: 50%;
						}

						.username {
							font-style: normal;
							font-weight: 400;
              margin-top: .5vw;
							//font-size: 1vw;
							color: #32435C;
							text-align: left;
						}

						.title-group {
							margin: 5px 0;
							font-weight: bold;

							.topic {
								color: #007DD1;
                font-size: 17px;
							}
							.title {
								margin-left: 10px;

							}
						}

						.text {
              //font-size: .8vw;
							color: #838383;
							margin: 5px 0;
							//height: 250px;
							overflow: hidden;
						}

						.teacher {
							font-size: 16px;
							font-weight: bold;
							text-align: right;
						}
					}

					.qrcode-question {
						background: #FFFFFF;
						//width: 27%;
						height: 19.5vw;
            margin-top: 3vw;

						border: 1px solid #E7E7EA;
						box-sizing: border-box;
						box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
						border-radius: 20px;
						//margin-top: 70px;
						padding: 1vw 3vw;
						.text-row {
							display: flex;
							align-items: center;
							font-size: 1.3vw;
							font-weight: 700;
              .title{
                //font-size: 1vw;
              }
							img.question-icon {
								width: 40px;
								height: 53px;
								margin-left: 15px;
							}
						}

						.img-wrapper {
							display: flex;
							justify-content: center;

							img.qrcode {
								width: 14vw;
								height: 14vw;
							}
						}
					}
				}
			}
		}
</style>
