<template>
<div style="padding:0px 100px;">
  <section class="testimonials-section" >
    <div class="auto-container">
      <div class="sec-title">
        <div>学生感言</div>
      </div>
      <div class="carousel-box">
        <div class="testimonials-carousel" v-if="feedbacks">
          <carousel
            :autoplay="true"
            :margin="30"
            :items="length"
            :responsive="{
              0: { items: 1 },
              800: { items: 2 },
              1600: { items: 2 },
            }"
          >
            <div class="testi-block" v-for="(key, index) in length" :key="index">
              <div class="inner">
                <div class="icon"><span>“</span></div>
                <div class="info">
                  <div class="image">
                    <a href="/team"
                      ><img :src="feedbacks[index].student_image" alt=""
                    /></a>
                  </div>
                  <div class="name">{{ feedbacks[index].student_name }}</div>
                  <div class="designation">{{ feedbacks[index].school_description }}</div>
                </div>
                <div class="text">
                  {{ feedbacks[index].feedback_content }}
                </div>
              </div>
            </div>
            
          </carousel>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import { clientService } from "../services/client";
export default {
  name: "Testimonial",
  data(){
      return{
          feedbacks:'',
          length:""
      }
  },
  beforeMount() {
      this.getFeedbacks()
  },
  methods: {
    async getFeedbacks() {
      let results = await clientService.getStudentFeedbacks();
      this.length = results.length*2
      results = results.concat(results)
      this.feedbacks = results
    },
  },
};
</script>

<style scoped>
</style>
