<template>
  <section class="team-section no-padd-top">
    <div class="auto-container">
      <div class="section-title-area text-center">
        <h2 class="section-title section-space--bottom--50">
          成功案例 <span class="title-icon"></span>
        </h2>
      </div>
    </div>
    <div class="carousel-box">
      <div class="team-carousel" v-if="successStory">

          <carousel
            :items="10"
            :autoplay="true"
            :margin="30"
            :responsive="{
              0: { items: 1 },
              640: { items: 2 },
              992: { items: 3 },
              1200: { items: 4 },
              1500: { items: 4 },
              1600: { items: 5 },
            }"
          >
            <!--Team-->
            <div class="team-block" v-for="(key, index) in 9" :key="index">
              <div class="inner-box">
                <div class="image-box">
                  <a href="/about"
                    ><img :src="successStory[index].admission_notice" alt=""
                  /></a>
                  <ul class="social-links clearfix">
                    <li>
                      <a href="#"><p>{{ successStory[index].subject_apply_chs }}</p></a>
                    </li>
                  </ul>
                </div>
                <div class="lower-box">
                  <h5>
                    <a href="#">{{ successStory[index].school_apply_chs }}</a>
                  </h5>
                  <div class="designation">{{ successStory[index].name }}({{ successStory[index].from_place }})</div>
                </div>
              </div>
            </div>
          </carousel>

      </div>
    </div>
  </section>
</template>

<script>
import { schoolService } from "../../services/school";
export default {
  name: "TeamSection",
  data() {
    return {
      successStory: "",
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let succesStory = await schoolService.getSuccessCase();
      this.successStory = succesStory.results;
    },
  },
};
</script>

<style scoped>
</style>
