var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"team-section no-padd-top"},[_vm._m(0),_c('div',{staticClass:"carousel-box"},[(_vm.successStory)?_c('div',{staticClass:"team-carousel"},[_c('carousel',{attrs:{"items":10,"autoplay":true,"margin":30,"responsive":{
            0: { items: 1 },
            640: { items: 2 },
            992: { items: 3 },
            1200: { items: 4 },
            1500: { items: 4 },
            1600: { items: 5 },
          }}},_vm._l((9),function(key,index){return _c('div',{key:index,staticClass:"team-block"},[_c('div',{staticClass:"inner-box"},[_c('div',{staticClass:"image-box"},[_c('a',{attrs:{"href":"/about"}},[_c('img',{attrs:{"src":_vm.successStory[index].admission_notice,"alt":""}})]),_c('ul',{staticClass:"social-links clearfix"},[_c('li',[_c('a',{attrs:{"href":"#"}},[_c('p',[_vm._v(_vm._s(_vm.successStory[index].subject_apply_chs))])])])])]),_c('div',{staticClass:"lower-box"},[_c('h5',[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.successStory[index].school_apply_chs))])]),_c('div',{staticClass:"designation"},[_vm._v(_vm._s(_vm.successStory[index].name)+"("+_vm._s(_vm.successStory[index].from_place)+")")])])])])}),0)],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auto-container"},[_c('div',{staticClass:"section-title-area text-center"},[_c('h2',{staticClass:"section-title section-space--bottom--50"},[_vm._v(" 成功案例 "),_c('span',{staticClass:"title-icon"})])])])}]

export { render, staticRenderFns }