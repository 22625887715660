<template>
  <section class="services-section-two">
    <div class="auto-container">

      <div class="services">
        <div class="row clearfix">
          <div class="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div class="bottom-curve"></div>
              <div class="icon-box"><b-icon icon="patch-question"></b-icon></div>
              <h5><a href="#">方案评估</a></h5>
              <div class="text">Lorem ipsum is are many variations of pass of majority.</div>
              <div class="link-box"><a href="#"><span class="fa fa-angle-right"></span></a></div>
            </div>
          </div>

          <div class="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div class="bottom-curve"></div>
              <div class="icon-box"><b-icon icon="pencil-square"></b-icon></div>
              <h5><a href="#">留学申请</a></h5>
              <div class="text">Lorem ipsum is are many variations of pass of majority.</div>
              <div class="link-box"><a href="#"><span class="fa fa-angle-right"></span></a></div>
            </div>
          </div>

         
          <div class="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="inner-box wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
              <div class="bottom-curve"></div>
              <div class="icon-box"><b-icon icon="truck"></b-icon></div>
              <h5><a href="#">境外接机</a></h5>
              <div class="text">Lorem ipsum is are many variations of pass of majority.</div>
              <div class="link-box"><a href="#"><span class="fa fa-angle-right"></span></a></div>
            </div>
          </div>

         
          <div class="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div class="inner-box fadeInUp" data-wow-delay="900ms" data-wow-duration="1500ms">
              <div class="bottom-curve"></div>
              <div class="icon-box"><b-icon icon="house-door"></b-icon></div>
              <h5><a href="#" class="title-text">海外住宿</a></h5>
              <div class="text">Lorem ipsum is are many variations of pass of majority.</div>
              <div class="link-box"><a href="#"><span class="fa fa-angle-right"></span></a></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {

    }
</script>