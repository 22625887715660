<template>
  <div class="service-grid-item">
    <div class="service-grid-item__image">
      <div class="service-grid-item__image-wrapper">
        <router-link :to="'/service-details?id='+ service.id">
          <img
            :src="
              this.$baseUrl +
              service.school_env_images[0].img
            "
            class="img-fluid"
            alt="service thumb"
          />
        </router-link>
      </div>
      <div v-if="mobile" class="icon">
        <img :src="this.$baseUrl + service.school_logo" />
      </div>
    </div>
    <div class="service-grid-item__content">
      <h3 class="title">
        <router-link :to="'/service-details?id='+ service.id">
          {{ service.name_chs }}
        </router-link>
      </h3>
      <p class="subtitle">{{ service.sub_title }}</p>
      <router-link class="see-more-link" :to="'/service-details?id='+ service.id">查看详情</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["service"],
  data() {
    return {
      mobile: false,
    };
  },
  created() {
    //(window.innerWidth);
    if (window.innerWidth > 500) {
      this.mobile = true;
    }
  },
};
</script>